<template>
  <div class="app-container">
    <div style="display:flex; flex-direction:row" class="page-title">
      <h2>{{ $t('parcelle.parcelles') }}</h2>
      <h2> 🗺</h2>
    </div>
    <el-row :gutter="40" class="panel-group">
      <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
        <div class="card-panel">
          <div class="card-panel-icon-wrapper icon-people">
            <h1>🗺</h1>
          </div>
          <div class="card-panel-description">
            <div class="card-panel-text">
              Total Parcelles
            </div>
            <count-to :start-val="0" :end-val="total" :duration="2600" class="card-panel-num" />
          </div>
        </div>
      </el-col>
    </el-row>
    <el-card v-if="checkRole(['admin'])" class="box-card filter-card just-for-admin">
      <div class="filter-container">
        <!-- <el-radio v-model="activeList" label="actives" border @change="getSubCampagneagricolesList('indexactive')">{{ $t('general.F_actives') }}</el-radio>-->
        <el-radio v-model="activeList" label="desactives" border @change="getSubParcellesList('indexdesactive')">{{
          $t('general.F_desactives')
        }}</el-radio>
        <el-radio v-model="activeList" label="disponibles" border @change="handleFilterParcelles">{{
          $t('general.disponibles')
        }}</el-radio>
        <el-radio
          v-model="activeList"
          label="corbeille"
          class="danger-radio"
          border
          @change="getSubParcellesList('indexinvisible')"
        >{{ $t('general.corbeille') }}</el-radio>
      </div>
      <hr>
      <div style="display:flex;flex-direction:row;align-items:center; margin:20px">
        <el-tag>Filtres par Hiérarchie : </el-tag>
        <el-breadcrumb style="margin-left:10px" separator-class="el-icon-arrow-right">
          <el-breadcrumb-item v-if="zoneFilter !== null">
            <el-tag type="success">{{ nomZones[zoneFilter] }}</el-tag>
          </el-breadcrumb-item>
          <el-breadcrumb-item v-if="communeFilter !== null">
            <el-tag type="info">{{ nomCommunes[communeFilter] }}</el-tag>
          </el-breadcrumb-item>
          <el-breadcrumb-item v-if="villageFilter !== null">
            <el-tag type="warning">{{ nomVillages[villageFilter] }}</el-tag>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div style="display:flex; flex-direction:row">
        <el-select
          v-model="zoneFilter"
          placeholder="Zone"
          style="margin-right:10px; width:200px"
          @change="filterByZone(value)"
        >
          <el-option v-for="item in zones" :key="item.value" :label="item.nom" :value="item.id" />
        </el-select>
        <el-select
          v-if="zoneFilter !== null"
          v-model="communeFilter"
          placeholder="Commune"
          style="margin-right:10px; width:200px"
          @change="filterByCommune(value)"
        >
          <el-option v-for="item in communes" :key="item.id" :label="item.nom" :value="item.id" />
        </el-select>
        <el-select
          v-if="communeFilter !== null"
          v-model="villageFilter"
          placeholder="Village"
          style="margin-right:10px; width:200px"
          @change="filterByVillage(value)"
        >
          <el-option v-for="item in villages" :key="item.id" :label="item.nom" :value="item.id" />
        </el-select>
      </div>
    </el-card>

    <el-card class="box-card">
      <div class="filter-container">
        <div style="display:flex; flex-direction:row; align-items:baseline">
          <el-input
            v-model="query.keyword"
            :placeholder="$t('general.keyword')"
            class="wd-200 mr-15 filter-item"
            @input="handleFilterParcelles"
          />
          <el-button
            v-waves
            class="mr-15 filter-item"
            type="primary"
            icon="el-icon-search"
            @click="handleFilterParcelles"
          >
            {{ $t('general.search') }}
          </el-button>
          <el-button
            v-waves
            :loading="downloading"
            class="filter-item"
            type="primary"
            icon="el-icon-download"
            @click="handleDownload"
          >
            {{ $t('general.export') }}
          </el-button>
          <div
            style="display:flex; margin-left:auto; margin-right:10px; font-weight:bold; font-size:30px; color:rgba(0, 0, 0, 0.5)"
          >
            Total parcelles : ({{ total }})</div>
        </div>

        <!-- <el-select v-if="villageFilter!==null" v-model="producteurFilter" placeholder="Producteur" style="margin-right:10px; width:200px" @change="filterByProducteur(value)">
          <el-option
            v-for="item in producteurs"
            :key="item.id"
            :label="item.nom + ' ' + item.prenom"
            :value="item.id"
          />
        </el-select> -->
        <!-- <el-button v-if="checkPermission(['creer parcelle'])" class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="handlecreateParcelle">
          {{ $t('general.add') }}
        </el-button> -->
      </div>
      <el-table v-loading="loading" :data="list" border fit highlight-current-row style="width: 100%">
        <el-table-column align="center" label="Code" min-width="100px">
          <template slot-scope="scope">
            <span>{{ scope.row.code }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="Village" min-width="100px">
          <template slot-scope="scope">
            <span>{{ scope.row.parcelle.village }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="Producteur" min-width="100px">
          <template slot-scope="scope">
            <span>{{ scope.row.producteur }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('parcelle.superficie')" min-width="150px">
          <template slot-scope="scope">
            <span>{{ scope.row.parcelle.superficie }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('parcelle.nbr_arb')" min-width="150px">
          <template slot-scope="scope">
            <span>{{ scope.row.parcelle.nbr_arb }}</span>
          </template>
        </el-table-column>
        <!-- v-if="activeList!='corbeille' && checkPermission(['activer campagneagricole'])" -->
        <el-table-column
          v-if="checkPermission(['activer parcelle'])"
          align="center"
          :label="$t('general.status')"
          width="100"
        >
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.parcelle.is_active"
              @change="setParcelleStatus(scope.row.id, 'active')"
            />
          </template>
        </el-table-column>

        <el-table-column
          v-if="checkRole(['admin']) && (activeList == 'corbeille' || query.keyword)"
          align="center"
          :label="$t('general.restaurer')"
          width="100"
        >
          <template slot-scope="scope">
            <el-switch
              v-if="!scope.row.parcelle.is_visible"
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.parcelle.is_visible"
              @change="setParcelleStatus(scope.row.id, 'visible')"
            />
          </template>
        </el-table-column>

        <el-table-column
          v-if="checkPermission(['modifier parcelle', 'visible parcelle', 'supprimer parcelle'])"
          align="center"
          :label="1 === 1 ? $t('general.actions') : $t('general.suppressionDefinitive')"
          width="200"
        >
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.parcelle.is_visible && checkPermission(['modifier parcelle'])"
              type="primary"
              size="small"
              icon="el-icon-edit"
              style="margin-right:10px;"
              @click="handleEditParcelle(scope.row.id);"
            />
            <el-button
              type="info"
              size="small"
              icon="el-icon-view"
              style="margin-right:10px;"
              @click="navigateParcelle(scope.row.parcelle.code)"
            />
          </template>
        </el-table-column>
      </el-table>

      <pagination
        v-if="total > 0"
        :total="total"
        :page.sync="query.page"
        :limit.sync="query.limit"
        @pagination="getParcellesList"
      />

      <!-- ======= REGION USER DIALOG ======= -->
      <el-dialog
        :title="editing ? $t('parcelle.edit') + ' ' + currentParcelle.nom : $t('parcelle.AddParcelle')"
        :visible.sync="dialogCreateParcelle"
        :before-close="handleCloseCampagneagricoleCreateDraw"
        :loading="parcelleCreating"
        width="30%"
      >
        <div class="content-inputs">
          <el-form ref="parcelleForm" :rules="rules" :model="currentParcelle" label-position="left">
            <el-form-item :label="$t('parcelle.nom')" prop="nom">
              <el-input v-model="currentParcelle.nom" />
            </el-form-item>
            <el-form-item :label="$t('general.description')" prop="description">
              <el-input v-model="currentParcelle.description" type="textarea" />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="danger" @click="dialogCreateParcelle = false">
              {{ $t('permission.cancel') }}
            </el-button>
            <el-button
              type="primary"
              :loading="parcelleCreating"
              @click="editing ? updateParcelle() : createParcelle()"
            >
              {{ parcelleCreating ? $t('general.saving') : $t('general.save') }}
            </el-button>
          </div>
        </span>
      </el-dialog>

      <el-dialog
        title="Modification de parcelles"
        :visible.sync="parcelleEditDialog"
        :before-close="handleCloseCampagneagricoleCreateDraw"
        width="72%"
        center
      >
        <h3>{{ currentParcelle.code }}</h3>
        <el-form ref="parcelForm" :inline="true" :model="currentParcelle" label-position="top" class="demo-ruleForm">
          <el-form-item
            prop="village_id"
            :label="$t('parcelle.village_id')"
            :rules="[
              { required: true, message: 'veuillez renseigner ce champ', trigger: 'blur' },
            ]"
          >
            <el-select
              v-model="currentParcelle.village_id"
              class="filter-item"
              :placeholder="$t('village.village')"
              style="width: 100%;"
              prop="village_id"
            >
              <el-option selected :value="currentParcelle.village_id">
                {{ currentParcelle.village }}
              </el-option>
              <el-option
                v-for="(village, index) in villages"
                :key="index"
                :v-show="currentParcelle.village_id !== village.id"
                :label="village.nom | uppercaseFirst"
                :value="village.id"
                :disabled="!village.is_active"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            prop="superficie"
            :label="$t('parcelle.superficie')"
            :rules="[
              { required: true, message: $t('parcelle.SuperficieRequired'), trigger: 'blur' },
              { type: 'number', message: 'Veuillez entrer un nombre', trigger: ['blur', 'change'] }
            ]"
          >
            <el-input-number
              v-model="currentParcelle.superficie"
              :controls="false"
              style="width: 100%"
              :min="0"
              controls-position="right"
              :placeholder="$t('parcelle.superficie')"
              required
            />
          </el-form-item>
          <el-form-item :label="$t('parcelle.acc_saison')" prop="saison">
            <el-input-number
              v-model="currentParcelle.acc_saison"
              :controls="false"
              style="width: 100%"
              :min="0"
              controls-position="right"
              :placeholder="$t('parcelle.acc_saison')"
            />
          </el-form-item>
          <el-form-item :label="$t('parcelle.dist_hab')" prop="distance">
            <el-input-number
              v-model="currentParcelle.dist_hab"
              :controls="false"
              style="width: 100%"
              :min="0"
              controls-position="right"
              :placeholder="$t('parcelle.dist_hab')"
            />
          </el-form-item>
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item :label="$t('parcelle.nbr_arb')" prop="arbre">
              <el-input-number
                v-model="currentParcelle.nbr_arb"
                :controls="false"
                style="width: 100%"
                :min="0"
                controls-position="right"
                :placeholder="$t('parcelle.nbr_arb')"
              />
            </el-form-item>
            <el-form-item :label="$t('parcelle.riviere')" prop="riviere">
              <el-input-number
                v-model="currentParcelle.riviere"
                :controls="false"
                style="width: 100%"
                :min="0"
                controls-position="right"
                :placeholder="$t('parcelle.riviere')"
              />
            </el-form-item>
            <el-form-item :label="$t('parcelle.date_dernier_usage')" prop="date_usage">
              <el-input v-model="currentParcelle.date_dernier_usage" :placeholder="$t('parcelle.date_dernier_usage')" />
            </el-form-item>
            <el-form-item :label="$t('parcelle.observation')" prop="observation">
              <el-input
                v-model="currentParcelle.observation"
                prefix=" "
                type="textarea"
                :placeholder="$t('parcelle.observation')"
              />
            </el-form-item>
          </el-form>
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item :label="$t('parcelle.statut')" prop="statut">
              <el-input
                v-model="currentParcelle.statut"
                style="width: 100%"
                :min="0"
                controls-position="right"
                :placeholder="$t('parcelle.statut')"
              />
            </el-form-item>
            <el-form-item :label="$t('parcelle.libelle')" prop="libelle">
              <el-input v-model="currentParcelle.libelle" style="width: 100%" :min="0" controls-position="right" :placeholder="$t('parcelle.libelle')" />
            </el-form-item>
            <el-form-item :label="$t('parcelle.zone_tp')" prop="zone">
              <el-select v-model="currentParcelle.zone_tp" style="width: 100%" :placeholder="$t('parcelle.zone_tp')">
                <el-option :label="$t('general.yes')" :value="true" />
                <el-option :label="$t('general.no')" :value="false" />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('parcelle.dist_zn_tp')" prop="distance_ztp">
              <el-input-number
                v-model="currentParcelle.dist_zn_tp"
                :controls="false"
                style="width: 100%"
                :min="0"
                controls-position="right"
                :placeholder="$t('parcelle.dist_zn_tp')"
              />
            </el-form-item>
            <el-form-item :label="$t('parcelle.cult_zn_tp')" prop="cult_zn_tp">
              <el-input v-model="currentParcelle.cult_zn_tp" type="text" :placeholder="$t('parcelle.cult_zn_tp')" />
            </el-form-item>
          </el-form>
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item :label="$t('parcelle.visa_ci')" prop="visa_ci">
              <el-select
                v-model="currentParcelle.visa_ci"
                :controls="false"
                style="width: 100%"
                :placeholder="$t('parcelle.visa_ci')"
              >
                <el-option :label="$t('general.yes')" :value="true" />
                <el-option :label="$t('general.no')" :value="false" />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('parcelle.visa_prod')" prop="visa_prod">
              <el-select
                v-model="currentParcelle.visa_prod"
                style="width: 100%"
                :placeholder="$t('parcelle.visa_prod')"
              >
                <el-option :label="$t('general.yes')" :value="true" />
                <el-option :label="$t('general.no')" :value="false" />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('parcelle.produits')" prop="produits">
              <el-select v-model="produitsAffecte" value-key="id" multiple style="width: 100%" placeholder="Select">
                <el-option
                  v-for="(item, index) in produits"
                  :key="index"
                  style="width: 100%"
                  :label="item.nom"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-form>
          <el-button @click="parcelleEditDialog = false">Annuler</el-button>
          <el-button type="primary" @click="updateParcelle">
            {{ $t('general.saving') }}
          </el-button>
        </el-form>
      </el-dialog>

      <!-- ======= REGION USER DIALOG ======= -->

    </el-card>
    <img
      width="100%"
      src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.cashadvance6online.com%2Fdata%2Farchive%2Fimg%2F3012921946.png&f=1&nofb=1"
      alt="grass bottom"
    >
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
import CountTo from 'vue-count-to';

const parcelleResource = new Resource('parcelles');
const zoneResource = new Resource('zones');
const communeResource = new Resource('communes');
const villageResource = new Resource('villages');
const producteursResource = new Resource('producteurs');
const produitResource = new Resource('produits');
export default {
  name: 'ParcellesList',
  components: { Pagination, CountTo },
  directives: { waves, permission, role },
  data() {
    return {
      list: null,
      zones: null,
      communes: null,
      communesBack: null,
      produits: {},
      villagesBack: null,
      villages: null,
      producteurs: null,
      zoneFilter: null,
      nomZones: [],
      produitsAffecte: [],
      nomCommunes: [],
      nomVillages: [],
      communeFilter: null,
      villageFilter: null,
      producteurFilter: null,
      total: 0,
      loading: true,
      downloading: false,
      parcelleCreating: false,
      editing: false,
      activeList: 'disponibles',
      currentParcelle: {},
      query: {
        page: 1,
        limit: 15,
        keyword: '',
      },
      queryAll: {
        page: 1,
        limit: 10000,
        keyword: '',
      },
      dialogCreateParcelle: false,
      parcelleEditDialog: false,
      currentParcelleId: 0,
      rules: {
        nom: [{ required: true, message: this.$t('parcelle.NomRequired'), trigger: 'blur' }],
      },
      fileList: [],
    };
  },
  computed: {

  },
  created() {
    this.getParcellesList();
    this.resetNewParcelle();
    this.getZonesList();
    this.getCommunesList();
    this.getVillagesList();
    this.getProducteursList();
    this.getProduitsList();
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array) {
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    handleCloseCampagneagricoleCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
    cancelparcelleForm() {
      this.$refs.CampagneagricoleCreationDrawer.closeDrawer();
    },
    navigateParcelle(id) {
      console.log('code de la parcelle : ', id);
      this.$router.push({ path: '/parcelles/' + id });
    },
    async setParcelleStatus(id, type) {
      const data = await parcelleResource.get('toogle_' + type + '/' + id);
      if (data.success) {
        this.$message({
          type: 'success',
          message: this.$t('general.statusUpdated'),
        });
        this.initSearch();
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
      }
    },
    async getSubParcellesList(subUrl) {
      this.loading = true;
      const { data } = await parcelleResource.sublist('/' + subUrl, this.query);
      this.list = data;
      this.loading = false;
    },
    initSearch() {
      this.handleFilterParcelles();
    },
    async filterByZone(value) {
      console.log('la zone : ', this.zoneFilter);
      this.loading = true;
      var requete = this.query;
      this.communeFilter = null;
      this.villageFilter = null;
      const communes = this.communesBack.filter(comm => comm.zone_id === this.zoneFilter);
      this.communes = communes;
      requete.zone_id = this.zoneFilter;
      requete.commune_id = null;
      requete.village_id = null;
      const { data } = await parcelleResource.list(requete);
      this.list = data.reverse();
      this.loading = false;
    },
    async filterByCommune(value) {
      console.log('la Commune : ', this.communeFilter);
      this.loading = true;
      var requete = this.query;
      this.villageFilter = null;
      const villages = this.villagesBack.filter(village => village.commune_id === this.communeFilter);
      this.villages = villages;
      requete.zone_id = this.zoneFilter;
      requete.commune_id = this.communeFilter;
      requete.village_id = null;
      const { data } = await parcelleResource.list(requete);
      this.list = data.reverse();
      this.loading = false;
    },
    async filterByVillage(value) {
      console.log('la Village : ', this.villageFilter);
      this.loading = true;
      var requete = this.query;
      requete.zone_id = this.zoneFilter;
      requete.commune_id = this.communeFilter;
      requete.village_id = this.villageFilter;
      const { data } = await parcelleResource.list(requete);
      this.list = data.reverse();
      this.loading = false;
    },
    // async filterByProducteur(value){
    //   console.log('la Producteur : ', this.producteurFilter);
    //   this.loading = true;
    //   var requete = this.query;
    //   requete.zone_id = this.zoneFilter;
    //   requete.commune_id = this.communeFilter;
    //   requete.village_id = this.villageFilter;
    //   requete.village_id = this.producteurFilter;
    //   const { data } = await parcelleResource.list(requete);
    //   this.list = data.reverse();
    //   this.loading = false;
    // },
    async getParcellesList() {
      this.loading = true;
      const { data, meta } = await parcelleResource.list(this.query);
      this.list = data.reverse();
      this.total = meta.total;
      console.log('===== EDI', this.total);
      console.log(data);
      console.log('===== EVO');
      this.loading = false;
    },
    async getZonesList() {
      const { data } = await zoneResource.list(this.queryAll);
      this.zones = data;
      const _reg = [];
      data.forEach(zone => {
        _reg[zone.id] = zone.nom;
      });
      this.nomZones = _reg;
    },
    async getCommunesList() {
      const { data } = await communeResource.list(this.queryAll);
      this.communes = data;
      const _reg = [];
      data.forEach(zone => {
        _reg[zone.id] = zone.nom;
      });
      this.nomCommunes = _reg;
      this.communesBack = data;
    },
    async getVillagesList() {
      const { data } = await villageResource.list(this.queryAll);
      this.villages = data;
      const _reg = [];
      data.forEach(zone => {
        _reg[zone.id] = zone.nom;
      });
      this.nomVillages = _reg;
      this.villagesBack = data;
    },
    async getProducteursList() {
      const { data } = await producteursResource.list(this.query);
      this.producteurs = data;
      this.producteursBack = data;
    },
    async getSubCampagneagricolesList(subUrl) {
      this.loading = true;
      const { data } = await parcelleResource.sublist('/' + subUrl, this.query);
      this.list = data;
      this.loading = false;
    },
    handleFilterParcelles() {
      this.query.page = 1;
      this.getParcellesList();
    },
    handlecreateParcelle() {
      this.editing = false;
      this.resetNewParcelle();
      this.dialogCreateParcelle = true;
      this.$nextTick(() => {
        // this.$refs['parcelleForm'].clearValidate();
        this.$refs['parcelForm'].clearValidate();
      });
    },
    handleEditParcelle(id) {
      this.editing = true;
      this.resetNewParcelle();
      this.currentParcelle = this.list.find(abonne => abonne.id === id).parcelle;
      this.currentParcelle.produits.forEach((item) => {
        this.produitsAffecte.push(item.id === undefined ? item : item.id);
      });
      console.log('the current parcelle is ', this.currentParcelle);
      console.log('Affected product ', this.produitsAffecte);
      this.parcelleEditDialog = true;
      this.$nextTick(() => {
        // this.$refs['parcelleForm'].clearValidate();
        this.$refs['parcelForm'].clearValidate();
      });
    },
    handleDeleteParcelle(id, visible, nom) {
      console.log('id : ' + id + 'nom : ' + nom);
      this.$confirm(this.$t('parcelle.deleteWarn') + ' ' + nom + '. ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Oui',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        console.log('result : ');
        if (!visible && (checkRole(['admin']) || checkPermission(['supprimer parcelle']))) {
          console.log('result 2 : ');
          this.$swal({
            title: this.$t('general.SureQuestion'),
            text: this.$t('general.irrversibleMessage'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('general.ouiSupprimer'),
          }).then((result) => {
            if (result.isConfirmed) {
              parcelleResource.destroy(id).then(response => {
                this.$message({
                  type: 'success',
                  message: this.$t('parcelle.suppressionEffectue'),
                });
                this.initSearch();
              }).catch(error => {
                console.log(error);
              });
            }
          });
        } else {
          this.setParcelleStatus(id, 'visible');
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('general.suppressionAnnule'),
        });
      });
    },
    createParcelle() {
      this.$refs['parcelleForm'].validate((valid) => {
        if (valid) {
          this.parcelleCreating = true;
          parcelleResource
            .store(this.currentParcelle)
            .then(async(response) => {
              this.$message({
                message: this.$t('parcelle.NewParcelle') + ' ' + this.currentParcelle.nom + ' ' + this.$t('parcelle.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.currentParcelleId = response.data.id;
              this.resetNewParcelle();
              this.dialogCreateParcelle = false;
              this.handleFilterParcelles();
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.parcelleCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    updateParcelle() {
      this.$refs['parcelForm'].validate((valid) => {
        if (valid) {
          console.log('AFTER MODIF', this.currentParcelle);
          this.currentParcelle.produits = this.produitsAffecte;
          const parcelle = (({
            superficie,
            libelle,
            nbr_arb,
            date_dernier_usage,
            dist_hab,
            riviere,
            acc_saison,
            zone_tp,
            dist_zn_tp,
            cult_zn_tp,
            observation,
            produits,
          }) => ({
            superficie,
            libelle,
            nbr_arb,
            date_dernier_usage,
            dist_hab,
            riviere,
            acc_saison,
            zone_tp,
            dist_zn_tp,
            cult_zn_tp,
            observation,
            produits,
          }))(this.currentParcelle);

          console.log('Parcel to be affected', parcelle);
          parcelleResource
            .update(this.currentParcelle.id, parcelle)
            .then(async(response) => {
              if (response.success) {
                this.$message({
                  message: this.$t('parcelle.NewParcelle') + '' + this.$t('parcelle.isupdatedSuccessfully'),
                  type: 'success',
                  duration: 5 * 1000,
                });
              } else {
                this.$message({
                  message: response.message,
                  type: 'info',
                  duration: 5 * 1000,
                });
              }
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.parcelleEditDialog = false;
              this.currentParcelle = {};
              this.produitsAffecte = [];
            });
        } else {
          console.log('Formulaire non valide !!');
          return false;
        }
      });
    },
    async getProduitsList() {
      const { data } = await produitResource.list();
      this.produits = await data;
    },
    oldUpdateParcelle() {
      this.$refs['parcelleForm'].validate((valid) => {
        if (valid) {
          this.parcelleCreating = true;
          parcelleResource.update(this.currentParcelle.id, this.currentParcelle).then(response => {
            this.resetNewParcelle();
            this.handleFilterParcelles();
            this.$message({
              message: this.$t('parcelle.isupdatedSuccessfully'),
              type: 'success',
              duration: 5 * 1000,
            });
            this.dialogCreateParcelle = false;
            this.editing = false;
          })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.parcelleCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    resetNewParcelle() {
      this.currentParcelle = {
        nom: '',
        description: '',
      };
    },
    handleDownload() {
      this.downloading = true;
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['ID', this.$t('parcelle.nom'), this.$t('general.description')];
        const filterVal = ['id', 'nom', 'description'];
        const data = this.formatJson(filterVal, this.list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'Liste des parcelles SCIAGRI_' + moment().format('DD-MM-YYYY_hh-mm'),
        });
        this.downloading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    onChange(file, fileList) {
      var a = this.fileList;
      a.push({ name: file.name, url: file.url });
      // console.log('onChange begin');
      this.fileList = a;
      // console.log(this.fileList);
      // console.log('onChange end');
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-group {
  margin-top: 18px;

  .card-panel-col {
    margin-bottom: 32px;
  }

  .card-panel {
    height: 108px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);

    &:hover {
      .card-panel-icon-wrapper {
        color: #fff;
      }

      .icon-people {
        background: #40c9c6;
      }

      .icon-message {
        background: #36a3f7;
      }

      .icon-money {
        background: #f4516c;
      }

      .icon-shopping {
        background: #34bfa3
      }
    }

    .icon-people {
      color: #40c9c6;
    }

    .icon-message {
      color: #36a3f7;
    }

    .icon-money {
      color: #f4516c;
    }

    .icon-shopping {
      color: #34bfa3
    }

    .card-panel-icon-wrapper {
      float: left;
      margin: 14px 0 0 14px;
      padding: 16px;
      transition: all 0.38s ease-out;
      border-radius: 6px;
    }

    .card-panel-icon {
      float: left;
      font-size: 48px;
    }

    .card-panel-description {
      float: right;
      font-weight: bold;
      margin: 26px;
      margin-left: 0px;

      .card-panel-text {
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        margin-bottom: 12px;
      }

      .card-panel-num {
        font-size: 20px;
      }
    }
  }
}

@media (max-width:550px) {
  .card-panel-description {
    display: none;
  }

  .card-panel-icon-wrapper {
    float: none !important;
    width: 100%;
    height: 100%;
    margin: 0 !important;

    .svg-icon {
      display: block;
      margin: 14px auto !important;
      float: none !important;
    }
  }
}

.edit-input {
  padding-right: 100px;
}

.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}

.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}

.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;

  .block {
    float: left;
    min-width: 250px;
  }

  .clear-left {
    clear: left;
  }
}
</style>
